<template>
  <div class="page-wrapper section-space--inner--120">
    <div class="col-lg-12" style="margin-top:-60px">
      <div class="section-title-area text-center">
        <h2 class="section-title section-space--bottom--50">
          商家详情<span class="title-icon"></span>
        </h2>
      </div>
    </div>
    <!--Projects section start-->
    <div class="project-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 section-space--bottom--30">
            <div class="project-information">
              <h3>{{ info.name }}</h3>
              <ul>
                <li><strong>行业</strong>{{ info.industry }}</li>
                <li><strong>业务</strong>{{ info.main_business }}</li>
                <li><strong>区域</strong>{{ info.division }}</li>
                <li><strong>联系</strong>{{ info.contact_phone }}</li>
                <li><strong>状态</strong>{{ info.status }}</li>
                <li><strong>介绍</strong>{{ info.introduction }}</li>
              </ul>
            </div>
          </div>

          <div
            class="
              col-lg-8 col-12
              section-space--bottom--30
              pl-15 pl-sm-15 pl-xs-15
            "
          >
            <div class="col-12 section-space--bottom--40">
              <div class="project-image">
                <img
                  :src="info.thumb"
                  class="img-fluid"
                  style="width: 1270px; height: 460px"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="section-title-area text-center">
              <h2 class="section-title section-space--bottom--50">
                更多内容<span class="title-icon"></span>
              </h2>
            </div>
          </div>
          <div class="col-12">
            <div class="row row-5 image-popup">
              <div
                class="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10"
                v-for="gallery in info.pictures"
                :key="gallery.id"
              >
                <img :src="gallery" class="img-fluid" alt="thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Projects section end-->
  </div>
</template>

<script>
import data from "../../data/project.json";
export default {
  props: ["info"],
  data() {
    return {
      data,
    };
  },
};
</script>